import * as React from '@theia/core/shared/react'
import URI from '@theia/core/lib/common/uri'
import { inject, injectable, postConstruct } from '@theia/core/shared/inversify'
import { CommandRegistry, Path } from '@theia/core/lib/common'
import { WorkspaceCommands, WorkspaceService } from '@theia/workspace/lib/browser'
import { KeymapsCommands } from '@theia/keymaps/lib/browser'
import {
  codicon,
  CommonCommands,
  Key,
  KeyCode,
  LabelProvider,
  Message,
  PreferenceService,
  ReactWidget,
} from '@theia/core/lib/browser'
import { ApplicationInfo, ApplicationServer } from '@theia/core/lib/common/application-protocol'
import { FrontendApplicationConfigProvider } from '@theia/core/lib/browser/frontend-application-config-provider'
import { EnvVariablesServer } from '@theia/core/lib/common/env-variables'
import { WindowService } from '@theia/core/lib/browser/window/window-service'
import { nls } from '@theia/core/lib/common/nls'

/**
 * Default implementation of the `GettingStartedWidget`.
 * The widget is displayed when there are currently no workspaces present.
 * Some of the features displayed include:
 * - `open` commands.
 * - `recently used workspaces`.
 * - `settings` commands.
 * - `help` commands.
 * - helpful links.
 */
@injectable()
export class GettingStartedWidget extends ReactWidget {

  /**
   * The widget `id`.
   */
  static readonly ID = 'getting.started.widget'
  /**
   * The widget `label` which is used for display purposes.
   */
  static readonly LABEL = nls.localizeByDefault('Welcome')

  /**
   * The `ApplicationInfo` for the application if available.
   * Used in order to obtain the version number of the application.
   */
  protected applicationInfo: ApplicationInfo | undefined
  /**
   * The application name which is used for display purposes.
   */
  protected applicationName = FrontendApplicationConfigProvider.get().applicationName

  protected home: string | undefined

  /**
   * The recently used workspaces limit.
   * Used in order to limit the number of recently used workspaces to display.
   */
  protected recentLimit = 5
  /**
   * The list of recently used workspaces.
   */
  protected recentWorkspaces: string[] = []

  /**
   * Collection of useful links to display for end users.
   */
  protected readonly documentationUrl = 'https://app.turbosite.cloud/'
  protected readonly extensionUrl = 'https://app.turbosite.cloud/'
  protected readonly pluginUrl = 'https://app.turbosite.cloud/'

  @inject(ApplicationServer)
  protected readonly appServer: ApplicationServer

  @inject(CommandRegistry)
  protected readonly commandRegistry: CommandRegistry

  @inject(EnvVariablesServer)
  protected readonly environments: EnvVariablesServer

  @inject(LabelProvider)
  protected readonly labelProvider: LabelProvider

  @inject(WindowService)
  protected readonly windowService: WindowService

  @inject(WorkspaceService)
  protected readonly workspaceService: WorkspaceService

  @inject(PreferenceService)
  protected readonly preferenceService: PreferenceService

  @postConstruct()
  protected init(): void {
    this.doInit()
  }

  protected async doInit(): Promise<void> {
    this.id = GettingStartedWidget.ID
    this.title.label = GettingStartedWidget.LABEL
    this.title.caption = GettingStartedWidget.LABEL
    this.title.closable = true

    this.applicationInfo = await this.appServer.getApplicationInfo()
    this.recentWorkspaces = await this.workspaceService.recentWorkspaces()
    this.home = new URI(await this.environments.getHomeDirUri()).path.toString()
    this.update()
  }

  protected override onActivateRequest(msg: Message): void {
    super.onActivateRequest(msg)
    const elArr = this.node.getElementsByTagName('a')
    if (elArr && elArr.length > 0) {
      (elArr[0] as HTMLElement).focus()
    }
  }

  /**
   * Render the content of the widget.
   */
  protected render(): React.ReactNode {
    return <div className="gs-container">
      <div className="gs-content-container">
        {this.renderHeader()}
        <hr className="gs-hr"/>

        <div className="flex-grid">
          <div className="col">
            {this.renderSettings()}
          </div>
        </div>

        <div className="flex-grid">
          <div className="col">
            {this.renderHelp()}
          </div>
        </div>
        <div className="flex-grid">
          <div className="col">
            {this.renderVersion()}
          </div>
        </div>
      </div>
      <div className="gs-preference-container">
        {this.renderPreferences()}
      </div>
    </div>
  }

  /**
   * Render the widget header.
   * Renders the title `{applicationName} Getting Started`.
   */
  protected renderHeader(): React.ReactNode {
    return <div className="gs-header">
      <h1>{this.applicationName}<span className="gs-sub-header">{' ' + GettingStartedWidget.LABEL}</span></h1>
    </div>
  }

  /**
   * Render the settings section.
   * Generally used to display useful links.
   */
  protected renderSettings(): React.ReactNode {
    return <div className="gs-section">
      <h3 className="gs-section-header">
        <i className={codicon('settings-gear')}></i>
        {nls.localizeByDefault('Settings')}
      </h3>
      <div className="gs-action-container">
        <a
          role={'button'}
          tabIndex={0}
          onClick={this.doOpenSelectColorTheme}
          onKeyDown={this.doOpenSelectColorThemeEnter}>
          {nls.localizeByDefault('Open Color Theme')}
        </a>
      </div>
    </div>
  }

  /**
   * Render the help section.
   */
  protected renderHelp(): React.ReactNode {
    return <div className="gs-section">
      <h3 className="gs-section-header">
        <i className={codicon('question')}></i>
        {nls.localizeByDefault('Help')}
      </h3>
      <div className="gs-action-container">
        <a
          role={'button'}
          tabIndex={0}
          onClick={() => this.doOpenExternalLink(this.documentationUrl)}
          onKeyDown={(e: React.KeyboardEvent) => this.doOpenExternalLinkEnter(e, this.documentationUrl)}>
          {nls.localizeByDefault('Documentation')}
        </a>
      </div>
      <div className="gs-action-container">
        <a
          role={'button'}
          tabIndex={0}
          onClick={() => this.doOpenExternalLink(this.extensionUrl)}
          onKeyDown={(e: React.KeyboardEvent) => this.doOpenExternalLinkEnter(e, this.extensionUrl)}>
          {nls.localize('theia/getting-started/newExtension', 'Building a New Extension')}
        </a>
      </div>
      <div className="gs-action-container">
        <a
          role={'button'}
          tabIndex={0}
          onClick={() => this.doOpenExternalLink(this.pluginUrl)}
          onKeyDown={(e: React.KeyboardEvent) => this.doOpenExternalLinkEnter(e, this.pluginUrl)}>
          {nls.localize('theia/getting-started/newPlugin', 'Building a New Plugin')}
        </a>
      </div>
    </div>
  }

  /**
   * Render the version section.
   */
  protected renderVersion(): React.ReactNode {
    return <div className="gs-section">
      <div className="gs-action-container">
        <p className="gs-sub-header">
          {this.applicationInfo ? nls.localizeByDefault('Version: {0}', this.applicationInfo.version) : ''}
        </p>
      </div>
    </div>
  }

  protected renderPreferences(): React.ReactNode {
    return <WelcomePreferences preferenceService={this.preferenceService}></WelcomePreferences>
  }

  /**
   * Build the list of workspace paths.
   * @param workspaces {string[]} the list of workspaces.
   * @returns {string[]} the list of workspace paths.
   */
  protected buildPaths(workspaces: string[]): string[] {
    const paths: string[] = []
    workspaces.forEach(workspace => {
      const uri = new URI(workspace)
      const pathLabel = this.labelProvider.getLongName(uri)
      const path = this.home ? Path.tildify(pathLabel, this.home) : pathLabel
      paths.push(path)
    })
    return paths
  }

  /**
   * Trigger the open command.
   */
  protected doOpen = () => this.commandRegistry.executeCommand(WorkspaceCommands.OPEN.id)
  protected doOpenEnter = (e: React.KeyboardEvent) => {
    if (this.isEnterKey(e)) {
      this.doOpen()
    }
  }

  /**
   * Trigger the open file command.
   */
  protected doOpenFile = () => this.commandRegistry.executeCommand(WorkspaceCommands.OPEN_FILE.id)
  protected doOpenFileEnter = (e: React.KeyboardEvent) => {
    if (this.isEnterKey(e)) {
      this.doOpenFile()
    }
  }

  /**
   * Trigger the open folder command.
   */
  protected doOpenFolder = () => this.commandRegistry.executeCommand(WorkspaceCommands.OPEN_FOLDER.id)
  protected doOpenFolderEnter = (e: React.KeyboardEvent) => {
    if (this.isEnterKey(e)) {
      this.doOpenFolder()
    }
  }

  /**
   * Trigger the open workspace command.
   */
  protected doOpenWorkspace = () => this.commandRegistry.executeCommand(WorkspaceCommands.OPEN_WORKSPACE.id)
  protected doOpenWorkspaceEnter = (e: React.KeyboardEvent) => {
    if (this.isEnterKey(e)) {
      this.doOpenWorkspace()
    }
  }

  /**
   * Trigger the open recent workspace command.
   */
  protected doOpenRecentWorkspace = () => this.commandRegistry.executeCommand(WorkspaceCommands.OPEN_RECENT_WORKSPACE.id)
  protected doOpenRecentWorkspaceEnter = (e: React.KeyboardEvent) => {
    if (this.isEnterKey(e)) {
      this.doOpenRecentWorkspace()
    }
  }

  protected doOpenSelectColorTheme = () => this.commandRegistry.executeCommand(CommonCommands.SELECT_COLOR_THEME.id)
  protected doOpenSelectColorThemeEnter = (e: React.KeyboardEvent) => {
    if (this.isEnterKey(e)) {
      this.doOpenSelectColorTheme()
    }
  }

  /**
   * Trigger the open keyboard shortcuts command.
   * Used to open the keyboard shortcuts widget.
   */
  protected doOpenKeyboardShortcuts = () => this.commandRegistry.executeCommand(KeymapsCommands.OPEN_KEYMAPS.id)
  protected doOpenKeyboardShortcutsEnter = (e: React.KeyboardEvent) => {
    if (this.isEnterKey(e)) {
      this.doOpenKeyboardShortcuts()
    }
  }

  /**
   * Open a workspace given its uri.
   * @param uri {URI} the workspace uri.
   */
  protected open = (uri: URI) => this.workspaceService.open(uri)
  protected openEnter = (e: React.KeyboardEvent, uri: URI) => {
    if (this.isEnterKey(e)) {
      this.open(uri)
    }
  }

  /**
   * Open a link in an external window.
   * @param url the link.
   */
  protected doOpenExternalLink = (url: string) => this.windowService.openNewWindow(url, { external: true })
  protected doOpenExternalLinkEnter = (e: React.KeyboardEvent, url: string) => {
    if (this.isEnterKey(e)) {
      this.doOpenExternalLink(url)
    }
  }

  protected isEnterKey(e: React.KeyboardEvent): boolean {
    return Key.ENTER.keyCode === KeyCode.createKeyCode(e.nativeEvent).key?.keyCode
  }
}

export interface PreferencesProps {
  preferenceService: PreferenceService;
}

function WelcomePreferences(props: PreferencesProps): JSX.Element {
  const [startupEditor, setStartupEditor] = React.useState<string>(
    props.preferenceService.get('workbench.startupEditor', 'welcomePage'),
  )
  React.useEffect(() => {
    const prefListener = props.preferenceService.onPreferenceChanged(change => {
      if (change.preferenceName === 'workbench.startupEditor') {
        const prefValue = change.newValue
        setStartupEditor(prefValue)
      }
    })
    return () => prefListener.dispose()
  }, [props.preferenceService])
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked ? 'welcomePage' : 'none'
    props.preferenceService.updateValue('workbench.startupEditor', newValue)
  }
  return (
    <div className="gs-preference">
      <input
        type="checkbox"
        className="theia-input"
        id="startupEditor"
        onChange={handleChange}
        checked={startupEditor === 'welcomePage' || startupEditor === 'welcomePageInEmptyWorkbench'}
      />
      <label htmlFor="startupEditor">
        {nls.localizeByDefault('Show welcome page on startup')}
      </label>
    </div>
  )
}
