// *****************************************************************************
// Copyright (C) 2019 TypeFox and others.
//
// This program and the accompanying materials are made available under the
// terms of the Eclipse Public License v. 2.0 which is available at
// http://www.eclipse.org/legal/epl-2.0.
//
// This Source Code may also be made available under the following Secondary
// Licenses when the conditions for such availability set forth in the Eclipse
// Public License v. 2.0 are satisfied: GNU General Public License, version 2
// with the GNU Classpath Exception which is available at
// https://www.gnu.org/software/classpath/license.html.
//
// SPDX-License-Identifier: EPL-2.0 OR GPL-2.0-only WITH Classpath-exception-2.0
// *****************************************************************************
export namespace MiniBrowserContentStyle {
    export const MINI_BROWSER = 'theia-mini-browser';
    export const TOOLBAR = 'theia-mini-browser-toolbar';
    export const TOOLBAR_READ_ONLY = 'theia-mini-browser-toolbar-read-only';
    export const PRE_LOAD = 'theia-mini-browser-load-indicator';
    export const FADE_OUT = 'theia-fade-out';
    export const CONTENT_AREA = 'theia-mini-browser-content-area';
    export const PDF_CONTAINER = 'theia-mini-browser-pdf-container';
    export const PREVIOUS = 'theia-mini-browser-previous';
    export const NEXT = 'theia-mini-browser-next';
    export const REFRESH = 'theia-mini-browser-refresh';
    export const OPEN = 'theia-mini-browser-open';
    export const BUTTON = 'theia-mini-browser-button';
    export const DISABLED = 'theia-mini-browser-button-disabled';
    export const TRANSPARENT_OVERLAY = 'theia-transparent-overlay';
    export const ERROR_BAR = 'theia-mini-browser-error-bar';
}
