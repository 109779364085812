var api = require("!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../node_modules/css-loader/dist/cjs.js!./materialcolors.css");

            content = content.__esModule ? content.default : content;

var refs = 0;
var update;
var options = {"injectType":"lazySingletonStyleTag","attributes":{"id":"theia-theme"}};

options.insert = "head";
options.singleton = true;

var exported = {};

exported.locals = content.locals || {};
exported.use = function() {
  if (!(refs++)) {
    update = api(content, options);
  }

  return exported;
};
exported.unuse = function() {
  if (refs > 0 && !--refs) {
    update();
    update = null;
  }
};



module.exports = exported;
